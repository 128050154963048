/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from "react";
import Header from "../partials/Header";
import QoSDonutChart from "../components/QoSDonutChart";
import { getThresholdColor } from "../utils/Threshold";
import Operators from "../partials/Operators";
import { LocationContext } from "../utils/LocationContext";
import { OPERATORS } from "../utils/config";
import { fetchOk } from "../utils/Api";

const QosCardWrapper = ({
  data,
  title,
  operator,
  network,
  service,
  heightValue,
}) => (
  <QoSDonutChart
    key={`data-${operator}-${network}-${service}`}
    title={title}
    subtitle=""
    range={service === "internet" ? (network === "3g" ? 1000 : 50) : 100}
    value={data[operator][`${network}_${service}`] ?? null}
    color={getThresholdColor(
      service === "voice" ? "sa" : "si",
      network,
      data[operator][`${network}_${service}`] ?? null
    )}
    unit={service === "internet" ? (network === "3g" ? "Kbps" : "Mbps") : "%"}
    style={{
      height: heightValue,
      width: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  />
);

function One() {
  // const data = Array(18).from(() => random(0, 100, true));
  const [dataQos, setDataQos] = useState(null);

  const [
    locationList,
    locationsUpdating,
    selectedLocation,
    setSelectedLocation,
  ] = useContext(LocationContext);

  useEffect(() => {
    if (selectedLocation !== null) {
      const defaultData = Object.fromEntries(
        OPERATORS.map((o) => [o.name, {}])
      );
      fetchOk(
        `https://atlas.arcep.bj/api/qos-data/${selectedLocation.type}/${selectedLocation.id}`
      )
        .then((r) => r.json())
        .then(({ data }) => {
          setDataQos({ ...defaultData, ...data });
          // setOperator((prevOperator) => (prevOperator.length > 0 ? prevOperator : [Object.keys(data).sort()[0]]));
        })
        .catch((e) => console.error(e));
    }
  }, [selectedLocation]);

  console.log(dataQos);

  return (
    <div className="w-full h-screen z-0 flex flex-col items-stretch relative dark:bg-gray-800">
      {/* Header */}
      <div className="grid grid-cols-1 grid-rows-1 gap-2 py-2 px-2">
        <Header />
      </div>

      {/* Operators lists */}
      <Operators />

      {/* Graphic */}
      <div className="grid grid-cols-3 grid-rows-3 gap-2 py-2 px-2 flex-grow relative z-0">
        <div className="flex justify-center p-6 text-6xl bg-white dark:bg-gray-900 drop-shadow-lg rounded-lg row-span-2 flex-wrap">
          {/* MTN */}
          {dataQos !== null && (
            <>
              <QosCardWrapper
                title="Ericsson 2G VOICE"
                data={dataQos}
                operator="MTN"
                service="voice"
                network="2g"
                heightValue="50%"
              />
              <QosCardWrapper
                title="Huawei 2G VOICE"
                data={dataQos}
                operator="MTN"
                service="voice"
                network="2g"
                heightValue="50%"
              />
              <QosCardWrapper
                title="Ericsson 3G VOICE"
                data={dataQos}
                operator="MTN"
                service="voice"
                network="3g"
                heightValue="50%"
              />
              <QosCardWrapper
                title="Huawei 3G VOICE"
                data={dataQos}
                operator="MTN"
                service="voice"
                network="3g"
                heightValue="50%"
              />
            </>
          )}
        </div>
        <div className="flex justify-center p-6 text-6xl bg-white dark:bg-gray-900 drop-shadow-lg rounded-lg row-span-2 flex-wrap">
          {/* MOOV */}
          {dataQos !== null && (
            <>
              <QosCardWrapper
                title="Ericsson 2G VOICE"
                data={dataQos}
                operator="MOOV"
                service="voice"
                network="2g"
                heightValue="50%"
              />
              <QosCardWrapper
                title="Huawei 2G VOICE"
                data={dataQos}
                operator="MOOV"
                service="voice"
                network="2g"
                heightValue="50%"
              />
              <QosCardWrapper
                title="Ericsson 3G VOICE"
                data={dataQos}
                operator="MOOV"
                service="voice"
                network="3g"
                heightValue="50%"
              />
              <QosCardWrapper
                title="Huawei 3G VOICE"
                data={dataQos}
                operator="MOOV"
                service="voice"
                network="3g"
                heightValue="50%"
              />
            </>
          )}
        </div>
        <div className="flex justify-center items-center text-center p-6 text-6xl bg-white dark:bg-gray-900 drop-shadow-lg rounded-lg row-span-2 flex-wrap">
          {/* BENIN TELECOMS */}
          {dataQos !== null && (
            <>
              <QosCardWrapper
                title="Huawei 2G VOICE"
                data={dataQos}
                operator="CELTIIS"
                service="voice"
                network="2g"
                heightValue="50%"
              />
              <QosCardWrapper
                title="Huawei 3G VOICE"
                data={dataQos}
                operator="CELTIIS"
                service="voice"
                network="3g"
                heightValue="50%"
              />
            </>
          )}
        </div>

        <div className="flex justify-center p-6 text-6xl bg-white dark:bg-gray-900 drop-shadow-lg rounded-lg">
          {/* MTN */}
          {dataQos !== null && (
            <>
              <QosCardWrapper
                title="Mixed KPI 2G VOICE"
                data={dataQos}
                operator="MTN"
                service="voice"
                network="2g"
                heightValue="100%"
              />
              <QosCardWrapper
                title="Mixed KPI 3G VOICE"
                data={dataQos}
                operator="MTN"
                service="voice"
                network="3g"
                heightValue="100%"
              />
            </>
          )}
        </div>

        <div className="flex justify-center p-6 text-6xl bg-white dark:bg-gray-900 drop-shadow-lg rounded-lg">
          {/* MOOV */}
          {dataQos !== null && (
            <>
              <QosCardWrapper
                title="Mixed KPI 2G VOICE"
                data={dataQos}
                operator="MOOV"
                service="voice"
                network="2g"
                heightValue="100%"
              />
              <QosCardWrapper
                title="Mixed KPI 3G VOICE"
                data={dataQos}
                operator="MOOV"
                service="voice"
                network="3g"
                heightValue="100%"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default One;
